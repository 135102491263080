import "react-datepicker/dist/react-datepicker.css";
import Image from "next/image";
import ReactDatePicker from "react-datepicker";
// import { format } from "date-fns";
import { logAnalyticsEvent } from "@/utils/logAnalyticsEvent";
import classNames from "classnames";
import { forwardRef, MouseEventHandler } from "react";

const CustomDatePicker = ({
  selected,
  onChange,
  minDate: minDate,
  logKey,
  label,
  maxDate,
}: {
  maxDate?: Date;
  selected: Date;
  onChange: Function;
  minDate?: Date;
  logKey: string;
  label: string;
}) => {
  const ButtomDateInput = forwardRef(
    ({ value, onClick }: { value?: any; onClick?: MouseEventHandler<HTMLButtonElement> }, ref) => (
      <div className="relative flex items-stretch flex-1 w-full">
        <button
          name="date picker"
          className="flex items-center justify-start w-full gap-2 px-3 py-4 text-base font-medium text-left border-none bg-inherit focus:outline-nonen ring-none text-my-dark-blue focus:ring-0 focus:border-none focus:outline-none lg:text-lg"
          onClick={onClick}
          ref={ref}
        >
          <Image src="/hero/calendar.svg" alt="location pin icon" height={24} width={24} />
          {value}
          {/* {selected.getFullYear() > new Date().getFullYear() && (
            <span> - {selected.getFullYear()}</span>
          )} */}
          {/* TODO: Removed year display */}
        </button>
      </div>
    )
  );
  ButtomDateInput.displayName = "BottomDateInput";

  return (
    <div className="relative w-full">
      <ReactDatePicker
        className="z-[9999]"
        customInput={<ButtomDateInput />}
        selected={selected}
        onInputClick={() => {
          logAnalyticsEvent(logKey, {});
        }}
        onChange={(date: Date) => {
          onChange(date);
        }}
        dateFormat="E, MMM d"
        dayClassName={(date: Date) =>
          classNames("!rounded-full !h-8 !w-8 !inline-flex !items-center !justify-center", {
            "!bg-primary-300": date.toDateString() === selected.toDateString(),
          })
        }
        calendarClassName="!bg-white"
        popperPlacement="top"
        dropdownMode="scroll"
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
};

export default CustomDatePicker;
