import Image from "next/image";

const Features = () => {
    return (
        <div className="flex items-center justify-start text-[12px] text-white bg-black bg-opacity-30 mt-auto gap-[25px] py-[10px] px-[22px] rounded-md font-medium">
            <div className="flex items-center justify-center gap-[7px] border-r border-[#948F8B] pr-4">
                <img
                    src="/hero/check-circle.svg"
                    alt="Check circle icon"
                    className="w-[24px] h-[24px]"
                />
                <p>Free cancellations on all bookings</p>
            </div>
            <div className="flex items-center justify-center gap-[7px] border-r border-[#948F8B] pr-4">
                <img
                    src="/hero/check-circle.svg"
                    alt="Check circle icon"
                    className="w-[24px] h-[24px]"
                />
                <p>60,000+ locations</p>
            </div>
            <div className="flex items-center justify-center gap-[7px] ">
                <img
                    src="/hero/check-circle.svg"
                    alt="Check circle icon"
                    className="w-[24px] h-[24px]"
                />
                <p>Customer support in 40+ languages</p>
            </div>
        </div>
    );
};

export default Features;
