import { create } from "zustand";

type Drive = {
  adData: {
    inlineItems: AdData[];
  } | null;
  setAdData: (adData: { inlineItems: AdData[] }) => void;
};

export const useAd = create<Drive>((set) => ({
  adData: null,
  setAdData: (adData) => set({ adData }),
}));
