import classNames from 'classnames'

const ToggleSwitch = ({ className, value, onChange, label }: {
    className: string,
    value: boolean,
    onChange: any,
    label: string
}) => {

    return (
        <div className={classNames(className, 'flex')} onClick={onChange}>
            <div className={classNames(
                'h-[21px] w-[30px] rounded-full flex p-[3px]',
                (value ? 'justify-end bg-[#0631CC]' : 'justify-start bg-gray-500')
            )}>
                <div className='bg-white rounded-full h-[15px] w-[15px]'></div>
            </div>
            <label className='text-my-dark-blue ms-[5px]'>
                <input id={'price-drop-alert-slider'} className='opacity-0 w-0 h-0' type={'checkbox'} checked={value} onChange={onChange} />
                {label}
            </label>
        </div>
    )
}

export default ToggleSwitch