import { useEffect } from "react";
import { useRouter } from "next/router";
import axios from "axios";

import getURL from "@/utils/getUrl";

type Props = {
  adData: AdData | undefined;
  type?: "top" | "bottom";
  countryCode: string;
};

const AdCard = (props: Props) => {
  const router = useRouter();
  let called = 0;

  const { adData } = props;
  useEffect(() => {
    if (called === 0 && adData) {
      called++;
      axios
        .get(`https://${getURL(props.countryCode)}${adData.impressionUrl}`)
        .then((res) => {
          // console.log(res.data, "Impression Registered");
        });
    }
  }, [adData]);

  return (
    <div
      className={`w-full fixed z-[1001] text-white flex items-center justify-center p-4 ${
        props.type === "bottom"
          ? "lg:bottom-[15vh] bottom-[15vh]"
          : "lg:top-[25vh] top-[10vh]"
      }`}
    >
      <div
        className="w-full rounded-xl shadow-md p-4 py-8 max-w-[350px] bg-cover bg-center bg-no-repeat search-btn-gradient"
        // style={{
        //   backgroundColor: "#043d86",
        //   backgroundImage: `url(/ad-bg.png)`,
        //   backgroundBlendMode: "multiply",
        // }}
      >
        {/* <div className="flex items-center justify-center w-full -mb-10 -translate-y-16">
          <img src="/special-offer.svg" className="" alt="Special Offer" />
        </div> */}
        {adData ? (
          <>
            <div className="flex items-baseline justify-center w-full py-4">
              <div className="flex items-center justify-center py-2 bg-white rounded-md">
                <img
                  src={`https://kayak.com${adData.logoUrl}`}
                  className="max-h-[30px] min-w-[80px] mx-2"
                  alt="Logo"
                />
              </div>
            </div>
            <div>
              <h2 className="font-[600] text-[24px] lg:text-[26px] text-white max-h-[80px] text-ellipsis !text-white text-center">
                {adData.headline}
              </h2>
              <p className="font-normal text-sm text-white mt-[8px] opacity-60 !text-white text-center">
                {adData.description}
              </p>
            </div>
            <button
              className="accent-primary w-full py-4 rounded-md font-[500] animate-zoom text-lg mt-8"
              onClick={() => {
                router.push(`https://kayak.com${adData.deepLink}`);
              }}
            >
              {adData.bookingButtonText}
            </button>
          </>
        ) : (
          <>
            <img
              src="/ad-bg.png"
              width={300}
              height={300}
              alt="load image"
              className=""
            />
            <div>
              <h2 className="font-[600] text-[24px] lg:text-[26px] text-white max-h-[80px] text-ellipsis !text-white text-center">
                Enjoy Amazing Deals!
              </h2>
              <p className="font-normal text-sm text-white mt-[8px] opacity-60 !text-white text-center">
                Check out our fantastic offers and book online with confidence
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdCard;
