export default function getURL(countryCode: string) {
	const countryURL: {
		[name: string]: { url: string }
	} = {
		ca: {
			url: 'www.ca.kayak.com',
		},
		cn: {
			url: 'www.cn.kayak.com',
		},
		uy: {
			url: 'www.es.kayak.com',
		},
		fi: {
			url: 'www.fi.kayak.com',
		},
		gr: {
			url: 'www.gr.kayak.com',
		},
		ae: {
			url: 'www.kayak.ae',
		},
		cat: {
			url: 'www.kayak.cat',
		},
		ch: {
			url: 'www.kayak.ch',
		},
		cl: {
			url: 'www.kayak.cl',
		},
		id: {
			url: 'www.kayak.co.id',
		},
		in: {
			url: 'www.kayak.co.in',
		},
		jp: {
			url: 'www.kayak.co.jp',
		},
		kr: {
			url: 'www.kayak.co.kr',
		},
		th: {
			url: 'www.kayak.co.th',
		},
		gb: {
			url: 'www.kayak.co.uk',
		},
		ar: {
			url: 'www.kayak.com.ar',
		},
		au: {
			url: 'www.kayak.com.au',
		},
		br: {
			url: 'www.kayak.com.br',
		},
		co: {
			url: 'www.kayak.com.co',
		},
		hk: {
			url: 'www.kayak.com.hk',
		},
		mx: {
			url: 'www.kayak.com.mx',
		},
		my: {
			url: 'www.kayak.com.my',
		},
		pe: {
			url: 'www.kayak.com.pe',
		},
		tr: {
			url: 'www.kayak.com.tr',
		},
		us: {
			url: 'www.kayak.com',
		},
		de: {
			url: 'www.kayak.de',
		},
		dk: {
			url: 'www.kayak.dk',
		},
		es: {
			url: 'www.kayak.es',
		},
		fr: {
			url: 'www.kayak.fr',
		},
		ie: {
			url: 'www.kayak.ie',
		},
		it: {
			url: 'www.kayak.it',
		},
		nl: {
			url: 'www.kayak.nl',
		},
		no: {
			url: 'www.kayak.no',
		},
		pl: {
			url: 'www.kayak.pl',
		},
		pt: {
			url: 'www.kayak.pt',
		},
		ru: {
			url: 'www.kayak.ru',
		},
		se: {
			url: 'www.kayak.se',
		},
		sg: {
			url: 'www.kayak.sg',
		},
		nz: {
			url: 'www.nz.kayak.com',
		},
		tw: {
			url: 'www.tw.kayak.com',
		},
		za: {
			url: 'www.za.kayak.com',
		},
	}

	if (countryURL[countryCode]) {
		return countryURL[countryCode]['url']
	} else {
		return 'www.kayak.com'
	}
}
