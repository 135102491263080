import { motion } from "framer-motion";
import { Player } from "@lottiefiles/react-lottie-player";

import AdCard from "./AdCard";
import { useEffect, useRef, useState } from "react";
import { useAd } from "@/hooks/useAd";
import { useRouter } from "next/router";

type Props = {
  handleSubmit: () => void;
  countryCode: string;
};

const AdLoader = ({ handleSubmit, countryCode }: Props) => {
  const router = useRouter();
  const animRef = useRef<Player>(null);
  const loaderLength = Math.floor(Math.random() * 75);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [countDown, setCountDown] = useState(10000);

  const isApp = router.query?.inapp;

  const { adData, setAdData } = useAd();

  useEffect(() => {
    const timer = setInterval(() => {
      if (countDown <= 0) return;
      setCountDown((prev) => prev - 1000);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const loaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (loaderRef.current) {
      loaderRef.current.style.width = `${loaderLength}%`;
    }
    const timer = setTimeout(() => {
      if (loaderRef.current) {
        loaderRef.current.style.width = "100%";
      }
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      animRef?.current?.play();
    }, 500);

    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 5000);
    setIsMounted(true);

    handleSubmit();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const searchRef = useRef<HTMLButtonElement>(null);

  return (
    <div className="fixed top-0 left-0 h-screen w-screen z-[999] bg-white">
      <div className="grid gap-2 mt-8 place-items-center">
        {/* <img src="/logo-color.svg" width={150} height={300} alt="Logo" /> */}
        <Rent80Logo />
      </div>

      <AdCard adData={adData?.inlineItems?.[0]} type="top" countryCode={countryCode} />
      {/* {ads?.inlineItems?.[1] && (
    <AdCard adData={ads?.inlineItems?.[1]} type="bottom" />
  )} */}
      <motion.div
        className="fixed left-0 flex flex-col items-center justify-center w-full bg-white bottom-6"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        viewport={{ once: true }}
      >
        <div className="relative w-full h-[20px] mx-auto ">
          <motion.p
            className="text-[16px] lg:text-[24px] text-black text-center absolute bottom-0 w-full "
            animate={{ opacity: [1, 0] }}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
              delay: 3,
            }}
          >
            Searching for the best deals
          </motion.p>
          <motion.p
            animate={{ opacity: [0, 1] }}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
              delay: 3,
            }}
            className="text-[16px] lg:text-[24px] text-black text-center absolute bottom-0 w-full "
          >
            Found the best deals on
          </motion.p>
        </div>
        <div className="pb-16 max-w-[800px] ">
          <Player
            src={`/lp/loader-${isApp ? "kayak" : "auto"}/anim.json`}
            keepLastFrame
            ref={animRef}
          />
        </div>
        <div className="relative w-full mx-auto">
          <div className="absolute bottom-0 flex items-center justify-center w-full">
            <motion.div
              className={`lg:w-[32vw] transition-opacity w-[80vw] duration-500 h-[18px] rounded-full p-1  mt-8`}
              animate={{ opacity: [1, 0] }}
              transition={{
                duration: 0.5,
                ease: "easeInOut",
                delay: 5,
              }}
            >
              <div
                ref={loaderRef}
                className="h-full rounded-full search-btn-gradient"
                style={{
                  width: "0%",
                  transition: "width 3s ease-out",
                }}
              />
            </motion.div>
          </div>
          <div className="absolute bottom-0 flex items-center justify-center w-full ">
            <motion.div
              className="w-full px-4 items-center flex justify-center pt-8 max-w-[350px] "
              animate={{ opacity: [0, 1] }}
              transition={{
                duration: 0.5,
                ease: "easeInOut",
                delay: 5,
              }}
            >
              <p>
                Redirecting to {isApp ? "KAYAK" : "AutoRentals"} in{" "}
                {countDown / 1000 > 0 ? Math.ceil(countDown / 1000) : "0"}
              </p>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

const Rent80Logo = () => {
  return (
    <svg
      className="h-8 md:h-10"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 278 64"
    >
      <path
        fill="#FFC700"
        d="M40.937 34.78a20.812 20.812 0 004.753-13.536 21.256 21.256 0 00-1.579-8.28 21.004 21.004 0 00-4.663-6.98C35.32 1.81 30.224 0 24.41 0H0v49.969h15.395v-7.125s11.14-.143 12.134-.215L42.667 64l12.275-9.36-14.005-19.86zm-16.53-7.558h-9.012V15.341h9.012a5.727 5.727 0 012.229.444c.707.296 1.349.73 1.89 1.28.54.548.969 1.2 1.26 1.917.292.718.44 1.486.438 2.262a5.991 5.991 0 01-1.69 4.21 5.818 5.818 0 01-4.127 1.768zM70.922 35.877v-4.97H91.56V18.371H70.922V13.69h21.005V0H55v50h37V35.877H70.922zM128.454.003v22.722L112.474.003 97 .075v49.853h15.474v-23.02L128.526 50l.143-.072H144V0l-15.546.003zM194 0l-4.43 15.345h-13.182V50h-15.414V15.345H148V0h46z"
      ></path>
      <path
        fill="#280069"
        fill-rule="evenodd"
        d="M232.39 6.66a11.219 11.219 0 00-3.085-3.236A16.252 16.252 0 00223.508.83 29.487 29.487 0 00216.493 0a38.174 38.174 0 00-7.26.67 20.435 20.435 0 00-6.487 2.452 11.906 11.906 0 00-4.56 4.5 11.928 11.928 0 00-1.571 6.215c0 .852.082 1.702.245 2.539a9.815 9.815 0 002.344 4.659 11.643 11.643 0 001.87 1.677 22.154 22.154 0 003.013 1.675c-1.171.367-2.32.801-3.44 1.3-.89.441-1.724.986-2.485 1.625a10.6 10.6 0 00-2.237 2.577 12.002 12.002 0 00-1.445 3.263 13.373 13.373 0 00-.478 3.564 11.833 11.833 0 001.305 5.613c.876 1.657 2.269 3.09 4.178 4.3a19.575 19.575 0 006.501 2.593c2.499.525 5.045.786 7.598.778a37.54 37.54 0 008.063-.864 20.656 20.656 0 006.929-2.808c.994-.661 1.898-1.44 2.694-2.318a29.007 29.007 0 01-4.063-29.108 29.016 29.016 0 015.183-8.241zm1.456 8.21c-.414.75-.787 1.524-1.116 2.32a22.879 22.879 0 00-.928 2.702c.352-.456.667-.941.94-1.45.434-.816.748-1.69.935-2.596.068-.323.124-.649.169-.976zm-2.866 11.504c.212.148.418.304.618.468a9.068 9.068 0 011.903 2.117 8.707 8.707 0 011.147 2.593c.237.947.355 1.919.352 2.895a14.44 14.44 0 01-.526 3.75 23.025 23.025 0 01-3.494-11.823zm-21.165 4.35c1.187-1.07 2.837-1.606 4.952-1.606l-.003-.012c1.949.006 3.47.458 4.563 1.357 1.093.9 1.64 2.147 1.64 3.742a5.863 5.863 0 01-.881 3.196 5.553 5.553 0 01-2.39 2.093 7.723 7.723 0 01-3.33.706 8.117 8.117 0 01-3.261-.652 4.946 4.946 0 01-2.29-1.925 5.084 5.084 0 01-.792-2.752 5.33 5.33 0 011.792-4.148zm1.443-19.429c1.079-1.037 2.547-1.555 4.405-1.555l-.018-.006c1.668 0 2.996.427 3.984 1.28a4.273 4.273 0 011.463 3.388 4.917 4.917 0 01-1.729 3.903 6.325 6.325 0 01-4.301 1.495 6.858 6.858 0 01-2.747-.547 4.251 4.251 0 01-1.974-1.675 4.564 4.564 0 01-.705-2.473 5.078 5.078 0 011.622-3.81z"
        clip-rule="evenodd"
      ></path>
      <path
        fill="#280069"
        fill-rule="evenodd"
        d="M273.566 39.027a23.7 23.7 0 003.995-13.17 23.729 23.729 0 00-23.703-23.703 23.704 23.704 0 1019.708 36.873zM244.047 22.9c.905 1.181 2.169 1.772 3.792 1.772a6.085 6.085 0 004.256-1.578 6.976 6.976 0 002.205-3.915 5.497 5.497 0 00-.009-2.263 5.346 5.346 0 00-.917-2.047c-.905-1.244-2.179-1.867-3.822-1.867-1.609-.053-3.176.546-4.38 1.673a6.931 6.931 0 00-2.141 4.113 5.313 5.313 0 00.078 2.174c.175.71.496 1.372.938 1.938zm16.705-6.53l-16.834 16.253 1.942 1.368 16.835-16.288-1.943-1.332zm-11.16 3.665a1.731 1.731 0 01-1.234.502l.006.006a1.155 1.155 0 01-.578-.109 1.218 1.218 0 01-.46-.383 1.483 1.483 0 01-.236-.595 1.518 1.518 0 01.034-.643 2.11 2.11 0 01.634-1.202c.32-.318.745-.492 1.185-.487a1.21 1.21 0 01.58.112c.182.084.343.213.47.375a1.507 1.507 0 01.247 1.202 2.183 2.183 0 01-.648 1.221zm4.346 14.856c.903 1.202 2.178 1.804 3.825 1.804l.006.006a6.025 6.025 0 004.232-1.616 6.965 6.965 0 002.175-3.95 5.36 5.36 0 00-.015-2.242 5.214 5.214 0 00-.926-2.017c-.913-1.234-2.183-1.851-3.81-1.851-1.591-.045-3.138.547-4.332 1.657a6.813 6.813 0 00-2.141 4.03 5.356 5.356 0 00.053 2.205c.17.722.489 1.396.933 1.974zm5.55-2.837c-.333.33-.775.512-1.233.506l.006.013a1.154 1.154 0 01-.579-.113 1.223 1.223 0 01-.459-.387 1.461 1.461 0 01-.202-1.205 2.13 2.13 0 01.633-1.234 1.659 1.659 0 011.186-.487c.201-.015.403.022.589.106.185.085.348.215.476.38a1.603 1.603 0 01.232 1.234 2.15 2.15 0 01-.649 1.187z"
        clip-rule="evenodd"
      ></path>
    </svg>
  );
};

export default AdLoader;
