import axios, { AxiosRequestConfig } from "axios";

type LogData = {
  uid: string; // An ID unique for each user.(The same id appended to the label sent to kayak) | REQUIRED
  label: string; // The label sent to kayak | REQUIRED
  distinctId?: string; // The distinct_id used by mixpanel if available
  route?: string; // Can take values “organic”, “ads”, “unknown” | REQUIRED
  countryCode?: string; // Country code of the user
  gclid?: string | string[]; // Google Click ID
  msclkid?: string | string[]; // Microsoft Click ID
  fbclid?: string | string[]; // Facebook Click ID
  adid?: string | string[]; // Adjust ID
  lastSeen?: Date; // Last time the user was seen
  currency?: string; // Preferred currency of the user
  atclid?: string | string[]; // AnyTrack Click ID
};

const productionAppTitle = "d1_web_car";

export function saveUser({
  uid,
  label,
  distinctId,
  route = "unknown",
  countryCode,
  gclid,
  msclkid,
  fbclid,
  adid,
  lastSeen,
  currency,
  atclid
}: LogData) {
  const data = {
    productionAppTitle,
    label,
    uid,
    distinctId,
    route,
    countryCode,
    gclid,
    msclkid,
    fbclid,
    adid,
    lastSeen,
    currency,
    atclid,
  };

  const userConfig: AxiosRequestConfig<any> = {
    headers: {
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_USERDATA_API_BEARER_TOKEN}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    // body: data,
  };

  try {
    // console.log(userConfig, data);
    axios
      .post(
        `${process.env.NEXT_PUBLIC_USERDATA_API_URL}/user/`,
        data,
        userConfig
      )
      .then((res) => {});
  } catch (error) {
    console.log("Error saving data: ", error);
  }
}
