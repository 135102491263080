import { create } from "zustand";

type PickupData = {
  pickupLocation: place | null;
  setPickupLocation: (pickupLocation: place) => void;
};

export const usePickup = create<PickupData>((set) => ({
  pickupLocation: null,
  setPickupLocation: (pickupLocation) => set({ pickupLocation }),
}));
